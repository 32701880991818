<template>
  <div class="body-login">
    <div class="content-overlay" />
    <div class="content-wrapper">
      <div class="content-header row" />
      <div class="content-body">
        <div class="auth-wrapper auth-v2">
          <b-row class="auth-inner m-0">

            <!-- Brand logo-->
            <a
              class="brand-logo"
              href="https://www.icrea.cat"
            />

            <!-- /Brand logo-->
            <!-- Left Text-->
            <div
              class="d-none d-lg-flex col-lg-8 align-items-center p-5"
              :style="{'background': 'url(' + require('@/assets/images/illustration/bg-login.svg') + ') center center no-repeat', 'background-size': 'cover'}"
            >
              <div
                class="
                  w-100
                  d-lg-flex
                  align-items-center
                  justify-content-center
                  flex-column
                  px-5
                "
              >
                <div class="mb-4">
                  <img
                    class="img-fluid"
                    :src="require('@/assets/images/logo/logo-icrea-white-login.svg')"
                    alt="ICREA"
                  >
                </div>
                <!-- <div class="quote">
                  <h1>
                    <em>"You never fail until you stop trying"</em>
                  </h1>
                  <hr>
                  <p>Albert Einstein</p>
                </div> -->
              </div>
            </div>
            <!-- /Left Text-->

            <!-- Login-->
            <b-col
              lg="4"
              class="d-flex align-items-center auth-bg px-2 p-lg-5"
            >
              <b-col
                sm="8"
                md="6"
                lg="12"
                class="px-xl-2 mx-auto"
              >
                <b-card-title
                  title-tag="h2"
                  class="font-weight-bold mb-1"
                >
                  Welcome to ICREA
                </b-card-title>
                <b-card-text class="mb-2">
                  If you are on this page it is because you have agreed to evaluate an ICREA member.
                </b-card-text>
                <b-card-text class="mb-2">
                  We have sent you a new email with instructions on how to carry out the evaluation and the username and password to access the ICREA intranet to carry out the evaluation.
                </b-card-text>

                <p class="card-text mb-2">
                  <strong>Thanks for helping!</strong>
                </p>
                <hr>
                <p class="card-text mt-2">
                  <small>If you do not receive the email with the accesses, check your spam or junk email folder. If you still cannot locate it, contact us at <a
                    href="mailto:ssalvado@icrea.cat"
                    title="contact with us"
                  >ssalvado@icrea.cat</a></small>
                </p>
              </b-col>
            </b-col>
            <!-- /Login-->
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'

require('@core/scss/core.scss')
require('@/assets/scss/style.scss')

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      loading: false,
      buttonTxt: 'Sign in',
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    loadingMode(status = true) {
      this.loading = status
    },
    async validationForm() {
      this.loadingMode()

      this.$refs.loginValidation.validate()
        .then(async success => {
          if (success) {
            await this.$store.dispatch('auth/login', {
              email: this.userEmail,
              password: this.password,
            }).then(() => {
              this.$router.push({ name: 'front.academy-call' })
            })
          } else {
            this.loadingMode(false)
          }
          this.loadingMode(false)
          if (!this.user.id) {
            this.$toast.error('Credentials incorrect')
          }
        }).catch(exception => {
          this.loadingMode(false)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: exception,
              icon: 'ErrorIcon',
              variant: 'error',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
